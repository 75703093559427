import React, {Component} from "react"
import Layout from "../components/layout";
import SEO from "../components/seo";
import IBHCBanner from "../components/IBHCBanner";
import Image from "../components/image"

class InternationalBlackHealthConference extends Component {
    render() {
        return (
            <Layout
                headerElement={<IBHCBanner siteTitle="International Black Health Conference" />}
                scrollStepInPx={50}
                delayInMs={2}
            >
            <SEO title="International Black Health Conference" />
            <section id="section2">
                <div className="container">
                    <div className="row">
                    <div className="col-lg-7">
                        <div className="heading m-b-40">
                            <h2>Improving Black Health Outcomes Across The African Diaspora</h2>
                        </div>
                        
                        <div className="pt-4">
                            <span className="lead" style={{color: '#000', fontWeight: 'bold'}}>About this event</span>
                            <p className="pt-3" style={{fontSize: '17px', textAlign: 'justify'}}>The International Black Health Conference will be the first of its kind in Canada and represents an unprecedented opportunity for a local, national and international gathering 
                            for the sole purpose of improving Black health outcomes across the African Diaspora, informing clinical practice, policy change, showcasing achievements and promising practices.
                            </p>
                        </div>

                        <div className="pt-5">
                            <span className="lead" style={{color: '#000', fontWeight: 'bold'}}>Conference Goal:</span>
                            <p className="pt-3" style={{fontSize: '17px', textAlign: 'justify'}}>The overall goal of this conference is to develop a report outlining best practice approaches for identifying and leveraging strategic
                            multidisciplinary and multi-sectoral partnerships and targeted knowledge mobilization approaches for addressing health disparities in diverse Black communities in Canada and globally, 
                            through research, clinical services, health policy, community mobilization and recommendations.
                            </p>
                            <p style={{fontSize: '17px', textAlign: 'justify'}}> Therefore, the main audiences for this project include professors, researchers, students,
                            clinicians, health policymakers, community organizations, government officials, and diverse Black communities in Canada and globally.
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-4 offset-lg-1 col-md-12 text-light p-50 rounded" style={{  
                        backgroundImage: "url(" + "https://ctrlcode-prod-images.s3.ca-central-1.amazonaws.com/ANSDPAD/ansji-slim-height-bg.png" + ")",
                        backgroundPosition: 'center',
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat'
                    }}>
                        <div className="heading p-20 m-b-0">
                            <h2 className="text-colored" style={{ fontSize: 32, lineHeight: '28px', fontWeight: 'bold'}}>International Black Health Conference:</h2>
                            <p className="text-light" style={{ fontSize: 18, lineHeight: '22px' }}>In-Person Event <br /> Oct. 6 - 8, 2023</p>
                        </div>

                        <div className="pt-4">
                            <h3 style={{
                                    color: '#179c55',
                                    marginBottom: '4px',
                                    fontSize: '16px',
                            }}>
                                <i className="fa fa-calendar fa-1x m-b-10"></i> Date and time
                            </h3>
                            <p className="text-light" style={{ fontSize: 16 }}>Thu, Oct 6, 2023, 12:00 PM – <br />Sat, Oct 8, 2023, 6:30 PM EDT</p>
                        </div>

                        <div className="pt-5">
                            <h3 style={{
                                    color: '#179c55',
                                    marginBottom: '4px',
                                    fontSize: '16px',
                            }}>
                                <i className="fa fa-map-marker fa-1x m-b-10"></i> Location
                            </h3>
                            <p className="text-light" style={{ fontSize: 16 }}>Halifax Marriott Harbourfront Hotel
                                1919 Upper Water Street <br />
                                Halifax, NS B3J 3J5 <br />
                                View map
                            </p>
                        </div>

                        <div className="pt-5">
                            <h3 style={{
                                    color: '#179c55',
                                    marginBottom: '4px',
                                    fontSize: '16px',
                            }}>
                                <i className="fa fa-check fa-1x m-b-10"></i> Refund policy
                            </h3>
                            <p className="text-light" style={{ fontSize: 16 }}>Contact the organizer to request a refund. Eventbrite's fee is nonrefundable.</p>
                        </div>
                    </div>
                    </div>
                </div>
                </section>

                <section style={{padding: '120px 0'}}>
                <div className="container">
                    <div className="heading m-b-40">
                    <h2>Event Schedule</h2>
                    </div>
                    <div className="tabs p-r-20">

                    <ul className="nav nav-tabs nav-justified text-start" id="myTab">
                        <li className="nav-item" style={{fontSize: 17}}><a href="#day1" className="nav-link active px-0" data-bs-toggle="tab" aria-selected="active" style={{textAlign: 'left', color: '#000'}}><strong>Day 01</strong> <br />Thursday, October 6, 2023</a></li>
                    </ul>
                    <div className="tab-content mb-5 pb-4">
                        <div id="day1" className="tab-pane fade show active">
                            <div className="px-0 py-4 border-bottom">
                                <span style={{fontSize: 17}}><i className="fa fa-clock-o" /> 10:00am - 4:30pm</span>
                                <h5 style={{fontSize: 17}}>Registration</h5>
                                <h5 style={{fontSize: 17}}>Afternoon space held for community tours</h5>
                            </div>                            
                            <div className="px-0 py-4 border-bottom mt-3">
                                <span style={{fontSize: 17}}><i className="fa fa-clock-o" /> 5:00pm - 9:00pm</span>
                                <h5 style={{fontSize: 17}}>Welcome Ceremony: The Black Cultural Centre</h5>
                                <p className="m-b-0">*Transportation will be provided to the Black Cultural Centre (BCC)*</p>
                            </div>
                            <div className="px-0 py-4 border-bottom mt-3">
                                <span style={{fontSize: 17}}><i className="fa fa-clock-o" /> 5:00pm - 9:00pm</span>
                                <h5 style={{fontSize: 17}}>Meet and Greet (Drumming)</h5>
                            </div>
                            <div className="px-0 py-4 border-bottom mt-3">
                                <span style={{fontSize: 17}}><i className="fa fa-clock-o" /> 5:30pm - 5:50pm</span>
                                <h5 style={{fontSize: 17}}>MC - Barb Hamilton-Hinch</h5>
                            </div>   
                            <div className="px-0 py-4 border-bottom mt-3">
                                <span style={{fontSize: 17}}><i className="fa fa-clock-o" /> 5:50pm</span>
                                <h5 style={{fontSize: 17}}>Elder to open event conference</h5>
                                <h5 style={{fontSize: 17}}>Barb Hamilton-Hinch - land acknowledgements</h5>
                                <h5 style={{fontSize: 17}}>Zamani Millar and Amariah Bernard-Washington sing The Black National Anthem</h5>
                            </div>   
                            <div className="px-0 py-4 border-bottom mt-3">
                                <span style={{fontSize: 17}}><i className="fa fa-clock-o" /> 6:05pm</span>
                                <h5 style={{fontSize: 17}}>African Libation - Wayn Hamilton</h5>
                            </div>  
                            <div className="px-0 py-4 border-bottom mt-3">
                                <span style={{fontSize: 17}}><i className="fa fa-clock-o" /> 6:20pm</span>
                                <h5 style={{fontSize: 17}}>DPAD welcome everyone - tells who we are, how we work, why the conference exits, what is going to happen over the next two days</h5>
                            </div>  
                            <div className="px-0 py-4 border-bottom mt-3">
                                <span style={{fontSize: 17}}><i className="fa fa-clock-o" /> 6:25pm</span>
                                <h5 style={{fontSize: 17}}>Owen 'O' Sound Lee - performs his song</h5>
                                <h5 style={{fontSize: 17}}>Opening remarks (HAAC - Sharon, ABSW - Rachelle, HPI - Dr. Barb) </h5>
                                <h5 style={{fontSize: 17}}>Her Honour Mayaan E. Francis</h5>
                                <h5 style={{fontSize: 17}}>Ministers of Health - DHW (3)</h5>
                                <h5 style={{fontSize: 17}}>Federal Minister of Health - remarks</h5>
                                <h5 style={{fontSize: 17}}>ADM Provo</h5>
                            </div>       
                            <div className="px-0 py-4 border-bottom mt-3">
                                <span style={{fontSize: 17}}><i className="fa fa-clock-o" /> 6:50pm</span>
                                <h5 style={{fontSize: 17}}>Damilola Iduye introduces the Keynote Speaker</h5>
                            </div>  
                            <div className="px-0 py-4 border-bottom mt-3">
                                <span style={{fontSize: 17}}><i className="fa fa-clock-o" /> 6:55pm</span>
                                <h5 style={{fontSize: 17}}>Keynote speaker - Notisha Massaquoi - includes Q&amp;A</h5>
                            </div>  
                            <div className="px-0 py-4 border-bottom mt-3">
                                <span style={{fontSize: 17}}><i className="fa fa-clock-o" /> 7:40pm</span>
                                <h5 style={{fontSize: 17}}>Damilola Iduye thanks the Keynote Speaker</h5>
                            </div>            
                            <div className="px-0 py-4 border-bottom mt-3">
                                <span style={{fontSize: 17}}><i className="fa fa-clock-o" /> 7:45pm</span>
                                <h5 style={{fontSize: 17}}>Barb introduces Dr. Ron - NS Brotherhood/Sisterhood - Dr. Ron Milne - slide presentation</h5>
                            </div>  
                            <div className="px-0 py-4 border-bottom mt-3">
                                <span style={{fontSize: 17}}><i className="fa fa-clock-o" /> 8:15pm</span>
                                <h5 style={{fontSize: 17}}>Barb to introduce tomorrow's agenda &amp; provide closing remarks</h5>
                            </div>  
                            <div className="px-0 py-4 border-bottom mt-3">
                                <span style={{fontSize: 17}}><i className="fa fa-clock-o" /> 8:20pm - 9:00pm</span>
                                <h5 style={{fontSize: 17}}>Networking time (piped in music from the hotel) </h5>
                            </div>                                                                                                                     
                        </div>
                    </div>


                    <ul className="nav nav-tabs nav-justified text-start" id="myTab">
                        <li className="nav-item" style={{fontSize: 17}}><a href="#day2" className="nav-link active px-0" data-bs-toggle="tab" aria-selected="active" style={{textAlign: 'left', color: '#000'}}><strong>Day 02</strong> <br />Friday October 7, 2023<br /> Academic Day - Highlight projects, interconnectedness, introduce researchers and their projects, share short snappers and have discussion circles</a></li>
                    </ul>
                    <div className="tab-content mb-5 pb-4">
                        <div id="day2" className="tab-pane fade show active">
                            <div className="px-0 py-4 border-bottom">
                                <span style={{fontSize: 17}}><i className="fa fa-clock-o" /> 8:00am – 9:00am</span>
                                <h5 style={{fontSize: 17}}>Breakfast</h5>
                            </div>
                            <div className="px-0 py-4 border-bottom">
                                <span style={{fontSize: 17}}><i className="fa fa-clock-o" /> 9:00am</span>
                                <h5 style={{fontSize: 17}}>Barb to provide opening remarks - Housekeeping matters</h5>
                            </div>
                            <div className="px-0 py-4 border-bottom">
                                <span style={{fontSize: 17}}><i className="fa fa-clock-o" /> 9:10am</span>
                                <h5 style={{fontSize: 17}}>Barb introduces the Keynote Speaker</h5>
                            </div>
                            <div className="px-0 py-4 border-bottom">
                                <span style={{fontSize: 17}}><i className="fa fa-clock-o" /> 9:15am</span>
                                <h5 style={{fontSize: 17}}>Keynote by Dr. Jonathan Metzel: Structural Determinants of Health (Includes Q & A)</h5>
                            </div>
                            <div className="px-0 py-4 border-bottom">
                                <span style={{fontSize: 17}}><i className="fa fa-clock-o" /> 10:15am</span>
                                <h5 style={{fontSize: 17}}>Interdisciplinary Centre for Black Health - Dr. Jude Mary Cenat</h5>
                                <h5 style={{fontSize: 17}}>Peer Review HPI Journal and call for papers - Tari</h5>
                                <h5 style={{fontSize: 17}}>Sign up for table talks</h5>
                                <h5 style={{fontSize: 17}}>Evaluation conversation</h5>
                            </div>
                            <div className="px-0 py-4 border-bottom">
                                <span style={{fontSize: 17}}><i className="fa fa-clock-o" /> 10:40am</span>
                                <h5 style={{fontSize: 17}}><b>NETWORKING BREAK</b></h5>
                                <h5 style={{fontSize: 17}}>(Lead by community fitness/students) - Joy Chiekwe</h5>
                            </div>
                            <div className="px-0 py-4 border-bottom">
                                <span style={{fontSize: 17}}><i className="fa fa-clock-o" /> 10:50am</span>
                                <h5 style={{fontSize: 17}}>Table Talks - What Does Black Health Mean ( Guiding Questions for facilitators ) - Cafe Style (Discussion)</h5>
                                <h5 style={{fontSize: 17}}> - Health Policy - Rhonda Atwell - Raven (notetaker) </h5>
                                <h5 style={{fontSize: 17}}> - Health Services - Camilla Watkins </h5>
                                <h5 style={{fontSize: 17}}> - Health Education - Dr. Ifeyinwa </h5>
                                <h5 style={{fontSize: 17}}> - Mental Health - Robert Wright </h5>
                                <h5 style={{fontSize: 17}}> - Mental Health - Lana MacLean </h5>
                                <h5 style={{fontSize: 17}}> - Gender Violence &amp; Youth - Dr. Adwoa Onuora </h5>
                                <h5 style={{fontSize: 17}}> - TriCouncil Research SSHRC - Dr. OmiSoore/Dr. Barb - Brooklyn (notetaker) </h5>
                            </div>
                            <div className="px-0 py-4 border-bottom">
                                <span style={{fontSize: 17}}><i className="fa fa-clock-o" /> 12:00pm</span>
                                <h5 style={{fontSize: 17}}><b>LUNCH (presentation of Interpretive Dance) </b></h5>
                            </div>
                            <div className="px-0 py-4 border-bottom">
                                <span style={{fontSize: 17}}><i className="fa fa-clock-o" /> 1:00pm</span>
                                <h5 style={{fontSize: 17}}>- Plenary with the graduate students and recent graduates - Terrance Lewis - Facilitator, Tari Ajadi, Keisha Jefferies, Chelsea States, Korede Esan, Dele Bello</h5>
                            </div>
                            <div className="px-0 py-4 border-bottom">
                                <span style={{fontSize: 17}}><i className="fa fa-clock-o" /> 2:00pm</span>
                                <h5 style={{fontSize: 17}}><b>STRETCH BREAK (Lead by community fitness/students) </b></h5>
                            </div>
                            <div className="px-0 py-4 border-bottom">
                                <span style={{fontSize: 17}}><i className="fa fa-clock-o" /> 2:20pm</span>
                                <h5 style={{fontSize: 17}}>Toronto Presentations - Morris Beckford and Jacqueline St. Kitts</h5>
                            </div>
                            <div className="px-0 py-4 border-bottom">
                                <span style={{fontSize: 17}}><i className="fa fa-clock-o" /> 3:20pm</span>
                                <h5 style={{fontSize: 17}}>John Ariyo - Race Based Data, MSI Data, Health Equity Framework, Health Equity Metrics and Indicators</h5>
                                <h5 style={{fontSize: 17}}>Steve Ashton - IWK</h5>
                            </div>
                            <div className="px-0 py-4 border-bottom">
                                <span style={{fontSize: 17}}><i className="fa fa-clock-o" /> 4:20pm</span>
                                <h5 style={{fontSize: 17}}>Refreshment Break (need to budget it)</h5>
                            </div>
                            <div className="px-0 py-4 border-bottom">
                                <span style={{fontSize: 17}}><i className="fa fa-clock-o" /> 4:30pm</span>
                                <h5 style={{fontSize: 17}}>Sharon introduces US Consul General</h5>
                                <h5 style={{fontSize: 17}}>US Consul General - Lyra S. Carr</h5>
                            </div>
                            <div className="px-0 py-4 border-bottom">
                                <span style={{fontSize: 17}}><i className="fa fa-clock-o" /> 5:30pm</span>
                                <h5 style={{fontSize: 17}}>Closing Remarks by Barb</h5>
                            </div>
                            <div className="px-0 py-4 border-bottom">
                                <span style={{fontSize: 17}}><i className="fa fa-clock-o" /> 5:40pm</span>
                                <h5 style={{fontSize: 17}}>Dinner on your own &amp; free time</h5>
                            </div>
                            <div className="px-0 py-4 border-bottom mt-3">
                                <span style={{fontSize: 17}}><i className="fa fa-clock-o" /> End Time 5:00pm</span>
                                <h5 style={{fontSize: 17}}>Table Talks: What Does Black Health Mean?</h5>
                                <p className="m-b-0">Note: Attendees are responsible for their own evening dining</p>
                            </div>
                        </div>
                    </div> 



                    <ul className="nav nav-tabs nav-justified text-start" id="myTab">
                        <li className="nav-item" style={{fontSize: 17}}><a href="#day3" className="nav-link active px-0" data-bs-toggle="tab" aria-selected="active" style={{textAlign: 'left', color: '#000'}}><strong>Day 03</strong> <br />Saturday October 8, 2023<br /> Community Day - Highlight  community-led initiatives</a></li>
                    </ul>
                    <div className="tab-content mb-5 pb-4">
                        <div id="day3" className="tab-pane fade show active">
                            <div className="px-0 py-4 border-bottom">
                                <span style={{fontSize: 17}}><i className="fa fa-clock-o" /> 7:45am - 8:45am</span>
                                <h5 style={{fontSize: 17}}><b>Breakfast</b></h5>
                            </div>
                            <div className="px-0 py-4 border-bottom mt-3">
                                <span style={{fontSize: 17}}><i className="fa fa-clock-o" /> 8:45am</span>
                                <h5 style={{fontSize: 17}}>Barb to bring greetings/land acknowledgement</h5>
                                <h5 style={{fontSize: 17}}>DPAD - Michelle Williams - short presentation on historical context (15 minutes)</h5>
                                <h5 style={{fontSize: 17}}>- how our history has led us to where we are today</h5>
                            </div>
                            <div className="px-0 py-4 border-bottom mt-3">
                                <span style={{fontSize: 17}}><i className="fa fa-clock-o" /> 9:05am</span>
                                <h5 style={{fontSize: 17}}>Dammy introduces Dr. Gwenetta</h5>
                                <h5 style={{fontSize: 17}}>Keynote: Dr. Gwenetta Curry (University of Edinburgh)</h5>
                            </div>
                            <div className="px-0 py-4 border-bottom mt-3">
                                <span style={{fontSize: 17}}><i className="fa fa-clock-o" /> 10:05am</span>
                                <h5 style={{fontSize: 17}}>Mr. Dorbrene O'Marde (Reparations - UWI) - virtual</h5>
                            </div>
                            <div className="px-0 py-4 border-bottom mt-3">
                                <span style={{fontSize: 17}}><i className="fa fa-clock-o" /> 10:35am</span>
                                <h5 style={{fontSize: 17}}><b>NETWORKING BREAK - ( Lead by community fitness/students )</b></h5>
                            </div>
                            <div className="px-0 py-4 border-bottom mt-3">
                                <span style={{fontSize: 17}}><i className="fa fa-clock-o" /> 10:50am</span>
                                <h5 style={{fontSize: 17}}>Local projects - Moderator - Dr. OmiSoore</h5>
                                <h5 style={{fontSize: 17}}>- ABSW/HAAC - COVID-19 Initiatives - virtual</h5>
                                <h5 style={{fontSize: 17}}>- African Nova Scotian Health Strategy Update - Rhonda</h5>
                                <h5 style={{fontSize: 17}}>- ABSW - Tanya</h5>
                                <h5 style={{fontSize: 17}}>- HAAC - Sharon (7-10 minutes presentation and Q &amp; A time)</h5>
                            </div>
                            <div className="px-0 py-4 border-bottom">
                                <span style={{fontSize: 17}}><i className="fa fa-clock-o" /> 12:00pm</span>
                                <h5 style={{fontSize: 17}}><b>LUNCH (presentation from PLANS - Dr. Barb/Dr. Haase &amp; Timi) </b></h5>
                            </div>
                            <div className="px-0 py-4 border-bottom mt-3">
                                <span style={{fontSize: 17}}><i className="fa fa-clock-o" /> 1:45pm</span>
                                <h5 style={{fontSize: 17}}>Community Table Talks (Concurrent/Cafe session)</h5>
                                <h5 style={{fontSize: 17}}>- Justice - Robert Wright</h5>
                                <h5 style={{fontSize: 17}}>- Housing - Land Titles - Curtis Whiley</h5>
                                <h5 style={{fontSize: 17}}>- Education - BEA President - Karen Hudson/DBDLI - Sylvia Parris-Drummond</h5>
                                <h5 style={{fontSize: 17}}>- Immigration - ADAM - Babs, UACWA - Daisy During</h5>
                                <h5 style={{fontSize: 17}}>- Employment - Jenvere Gordan</h5>
                                <h5 style={{fontSize: 17}}>- Child Welfare - Emma Beukema</h5>
                                <h5 style={{fontSize: 17}}>- Environment - SEED - Vanessa Hartley/Louise Delisle</h5>
                                <h5 style={{fontSize: 17}}>- Determinants of Healthy Eating</h5>
                                <h5 style={{fontSize: 17}}>- Spirituality - Reverend Debbie Simmonds</h5>
                                <h5 style={{fontSize: 17}}>- Youth - ACCE - Lindell Smith</h5>
                            </div>
                            <div className="px-0 py-4 border-bottom">
                                <span style={{fontSize: 17}}><i className="fa fa-clock-o" /> 2:55pm</span>
                                <h5 style={{fontSize: 17}}><b>STRETCH Break (Joy Chiekwe) </b></h5>
                            </div>
                            <div className="px-0 py-4 border-bottom mt-3">
                                <span style={{fontSize: 17}}><i className="fa fa-clock-o" /> 3:15pm</span>
                                <h5 style={{fontSize: 17}}>Facilitator - Timi Idris</h5>
                                <h5 style={{fontSize: 17}}>Black health professions plenary - Black Physicians of Nova Soctia - Chad Williams</h5>
                                <h5 style={{fontSize: 17}}>Pan-Canadian Association of Nurses of African Descent - Damilola Iduye, Keisha Jefferies - Black Nurses Association of Nova Scotia, Shelly Philip-LaForest - Ontario Black Nurses Network)</h5>
                                <h5 style={{fontSize: 17}}>Justin Brook - Chiropractor</h5>
                            </div>
                            <div className="px-0 py-4 border-bottom mt-3">
                                <span style={{fontSize: 17}}><i className="fa fa-clock-o" /> 4:05pm</span>
                                <h5 style={{fontSize: 17}}>Conference wrap -up</h5>
                            </div>
                            <div className="px-0 py-4 border-bottom mt-3">
                                <span style={{fontSize: 17}}><i className="fa fa-clock-o" /> 7:00pm</span>
                                <h5 style={{fontSize: 17}}>Closing Dinners and Thank-You's</h5>
                            </div>
                        </div>
                    </div>   



                    <ul className="nav nav-tabs nav-justified text-start" id="myTab">
                        <li className="nav-item" style={{fontSize: 17}}><a href="#day4" className="nav-link active px-0" data-bs-toggle="tab" aria-selected="active" style={{textAlign: 'left', color: '#000'}}><strong>Day 04</strong> <br />Sunday October 9, 2023< br /> People can determine if they want to go to church on their own</a></li>
                    </ul>
                    <div className="tab-content mb-5 pb-4">
                        <div id="day4" className="tab-pane fade show active">
                            <div className="px-0 py-4 border-bottom mt-3">
                                <h5 style={{fontSize: 17}}>- Beechville Baptist Church &ndash; Beechville <br />
                                - New Horizons Baptist Church - Halifax <br />
                                - Emmanuel Baptist Church &ndash; Upper Hammonds Plains <br />
                                - Victoria Road United Baptist Church &ndash; Dartmouth <br />
                                - Cherry Brook United Baptist Church &ndash; Cherry Brook (Dartmouth) <br />
                                - East Preston United Baptist Church &ndash; East Preston (Dartmouth) <br />
                                - St. Thomas Baptist Church &ndash; North Preston (Dartmouth) <br />
                                - New Beginnings Ministries &ndash; Cherry Brook (Dartmouth) <br />
                                - St. Thomas More Catholic Church &ndash; Dartmouth (African Mass) <br />
                                - Anglican Church Service &ndash; Halifax/Dartmouth <br />
                                - African Church Services <br />
                                - Redeemed Christian Church of God (RCCG) &ndash; Spryfield, Halifax and Dartmouth <br />
                                - Pentecostal Options &ndash; Halifax and Dartmouth <br />
                                - Mosques</h5>
                            </div>
                        </div>
                    </div>                                                        
                    </div>
                </div>
                </section>

                <div className="call-to-action call-to-action-colored background-colored p-t-60 p-b-60 m-b-0 my-5" style={{backgroundColor: '#0d2039'}}>
                    <div className="container">
                        <div className="row">
                        <div className="col-lg-10">
                            <h3>Vendor Opportunity/Corporate Booth Available</h3>
                            <p style={{fontSize: '17px'}}>Please send email to: intlblackhealthconference@gmail.com for more information.</p>
                        </div>
                        <div className="col-lg-2"> <a href="https://www.eventbrite.com/e/international-black-health-conference-in-person-event-oct-6-8-2023-tickets-383251564747" className="btn btn-light btn-rounded" style={{fontSize: 16, padding: 18}}>Purchase Tickets</a></div>
                        </div>
                    </div>
                </div>

                <section id="section4" className="background-grey">
                <div className="container">
                    <div className="heading">
                    <h2 style={{marginTop: '100px'}}>SPEAKERS</h2>
                    </div>
                    <div className="row team-members">
                    <div className="col-lg-6">
                        <div className="team-member">
                        <div className="team-image">
                            <img src="https://ctrlcode-prod-images.s3.ca-central-1.amazonaws.com/ANSDPAD/notisha.jpeg" style={{height: '590px'}} />
                        </div>
                        <div className="team-desc">
                            <h3 style={{fontSize: '22px', fontWeight: 'bold', marginBottom: '12px'}}>Dr. Notisha Massaquoi</h3>
                            <span style={{fontSize: '18px', fontWeight: 'bold', color: '#000000'}}>Day 1 - Keynote Speaker</span>
                            <p className="py-4 px-4" style={{fontSize: '16px', textAlign: 'justify'}}>
                            Dr. Notisha Massaquoi is an Assistant Professor in the Department of Health and Society at the University of Toronto, Scarborough, with a graduate appointment in the Factor
                            Inwentash Faculty of Social Work. She is also the founder of the newly created Black Health Equity Lab (The BHEL).
                            </p>
                        </div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="team-member">
                        <div className="team-image">
                            <img src="https://ctrlcode-prod-images.s3.ca-central-1.amazonaws.com/ANSDPAD/johnathan.jpeg" style={{height: '590px'}} />
                        </div>
                        <div className="team-desc">
                            <h3 style={{fontSize: '22px', fontWeight: 'bold', marginBottom: '12px'}}>Dr. Johnathan Metzl</h3>
                            <span style={{fontSize: '18px', fontWeight: 'bold', color: '#000000'}}>Day 2 - Keynote Speaker</span>
                            <p className="py-4 px-4" style={{fontSize: '16px', textAlign: 'justify'}}>
                                Dr. Jonathan Metzl is the Frederick B. Rentschler II Professor of Sociology and Psychiatry, and the Director of the Center for Medicine, Health, and Society,
                                at Vanderbilt University in Nashville, Tennessee. He is also the Research Director of The Safe Tennessee Project, a non- partisan, volunteer-based organization 
                                that is concerned with gun-related injuries and fatalities in America and in Tennessee. <br /><br />
                                Check out his latest book Dying of Whiteness: <a href="https://www.dyingofwhiteness.com/">https://www.dyingofwhiteness.com/</a>
                            </p>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                </section>

                <section id="section6" className="no-padding">
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2838.3532729268127!2d-63.575931084149914!3d44.6511334790999!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4b5a23ce0bdf28a3%3A0x69b035c0d9b40239!2s1919%20Upper%20Water%20St%2C%20Halifax%2C%20NS%20B3J%203N2!5e0!3m2!1sen!2sca!4v1661305629319!5m2!1sen!2sca" width="1920" height="600" style={{border: 0}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                </section>

                <section className="p-t-100 p-b-80">
                    <div className="container">
                    <div className="pt-4 text-center">
                            <p className="lead" style={{color: '#000', fontWeight: 'bold'}}>International Black Health Conference: In-Person Event Oct. 6 - 8, 2023</p>
                            <p style={{fontSize: '17px', textAlign: 'center'}}>
                                at <br />
                                <span style={{color: '#000'}}>Halifax Marriott Harbourfront Hotel</span> <br />
                                1919 Upper Water Street, Halifax, NS B3J 3J5
                            </p>
                        </div>
                    </div>
                </section>                
            </Layout>
        );
    }
}

export default InternationalBlackHealthConference